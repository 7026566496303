import { __rest } from "tslib";
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { produceWithoutFreeze } from '@prophecy/utils/data';
import { getIndexAndParentPath, PATH_KEY, updatePath } from '@prophecy/utils/nestedData';
import { usePersistentCallback } from '@prophecy/utils/react/hooks';
import { get, uniq } from 'lodash-es';
import React, { forwardRef, useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import { Button } from '../Button';
import { Dropdown } from '../Dropdown';
import { TrashCIcon } from '../Icons';
import { Stack, StackItem } from '../Layout';
import { TableRowExpandButton } from './components';
import { ExcelTable } from './ExcelTable';
import { canAddElseIfRow, canAddElseRow, canAddIndependentRows, correctNestedLogicalRows, createElseIfRow, createElseRow, createForInRow, createIfRow, ElseComponent, ElseIfRowComponent, ForComponent, IfRowComponent, wrapInIfGroup } from './LogicalRows';
import { TableActions } from './styled';
import { TableIconButton } from './TableIconButton';
import { tokens } from './tokens';
import { LogicalRows, RowPosition } from './types';
import { EXPANDED_SUFFIX, getExpandedDetailsRowsData } from './utils';
const LogicalRowCell = styled.td `
  background-color: ${tokens.LogicalRowCell.backgroundColor};

  &&& {
    padding-right: 0;
  }
`;
const RowComponentMap = {
    [LogicalRows.if]: IfRowComponent,
    [LogicalRows.elseIf]: ElseIfRowComponent,
    [LogicalRows.else]: ElseComponent,
    [LogicalRows.for]: ForComponent
};
export const TableRow = forwardRef((rowProps, ref) => {
    const { row, language, suggestions, dataSource, customizedRowComponent, children, onRowChange, onDataChange, expandIconColumnIndex = 0, buildNewRow, expandRow, prepareSuggestions, rowComponents } = rowProps, restProps = __rest(rowProps, ["row", "language", "suggestions", "dataSource", "customizedRowComponent", "children", "onRowChange", "onDataChange", "expandIconColumnIndex", "buildNewRow", "expandRow", "prepareSuggestions", "rowComponents"]);
    const { rowType } = row;
    let _children = children;
    if (rowType === LogicalRows.ifGroup) {
        return null;
    }
    else if (rowType) {
        const LogicalRowComponent = RowComponentMap[rowType];
        const childrenArray = React.Children.toArray(children);
        // add actionable columns added internally from Table component like (reorder column select column)
        const internalColumns = childrenArray.slice(0, expandIconColumnIndex);
        // const delete column
        const deleteColumn = childrenArray[childrenArray.length - 1];
        // extract appendNode (expand icon + indent nodes) from the column
        const expandIcon = childrenArray[expandIconColumnIndex].props.appendNode;
        _children = (_jsxs(_Fragment, { children: [internalColumns, _jsx(LogicalRowCell, { colSpan: childrenArray.length - expandIconColumnIndex - 1, children: _jsxs(Stack, { direction: 'horizontal', alignY: 'stretch', height: '100%', children: [_jsx(StackItem, { shrink: '0', children: expandIcon }), _jsx(StackItem, { grow: '1', children: _jsx(LogicalRowComponent, { row: row, dataSource: dataSource, language: language, suggestions: suggestions, prepareSuggestions: prepareSuggestions, onRowChange: onRowChange, onDataChange: onDataChange, buildNewRow: buildNewRow, expandRow: expandRow, rowComponents: rowComponents }) })] }) }), deleteColumn] }));
    }
    const TRElm = customizedRowComponent || 'tr';
    return (_jsx(TRElm, Object.assign({ ref: ref }, restProps, { children: _children })));
});
const TableCell = (_a) => {
    var { children, customizedCellComponent } = _a, restProps = __rest(_a, ["children", "customizedCellComponent"]);
    const TDElm = customizedCellComponent || 'td';
    return (_jsx(TDElm, Object.assign({}, restProps, { children: _jsx(Stack, { direction: 'horizontal', alignY: 'center', width: '100%', height: '100%', children: children }) })));
};
function getLogicalGroupKeys(data, rowKey, logicalGroupKeys) {
    data.forEach((item) => {
        var _a;
        if (item.rowType === LogicalRows.ifGroup) {
            logicalGroupKeys.push(item[rowKey]);
        }
        if ((_a = item.children) === null || _a === void 0 ? void 0 : _a.length) {
            getLogicalGroupKeys(item.children, rowKey, logicalGroupKeys);
        }
    });
    return logicalGroupKeys;
}
export function CodeTable(_a) {
    var { editable = true, language, suggestions, onRowChange, onDataChange, columns, dataSource = [], components, onRow, buildNewRow, newRowLabel = 'Row', rowKey = PATH_KEY, prepareSuggestions, rowComponents, withRowSelection = true, renderRowDetails } = _a, restProps = __rest(_a, ["editable", "language", "suggestions", "onRowChange", "onDataChange", "columns", "dataSource", "components", "onRow", "buildNewRow", "newRowLabel", "rowKey", "prepareSuggestions", "rowComponents", "withRowSelection", "renderRowDetails"]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [lastInteractedRow, setLastInteractedRow] = useState(undefined);
    const [expendedRows, setExpendedRows] = useState([]);
    const [expandedRowDetails, setExpandedRowDetails] = useState([]);
    const expandRow = (row) => {
        setExpendedRows(uniq([...expendedRows, row[rowKey]]));
    };
    const onReorder = ({ row, selectedRows, multiple, toIndex, fromIndex, currentRow }) => {
        const updatedData = produceWithoutFreeze(dataSource, (draft) => {
            const { parentPath: parentPathTo } = getIndexAndParentPath(currentRow[PATH_KEY]);
            const parentTo = parentPathTo ? get(draft, parentPathTo) : draft;
            // if single element is moved move the row
            if (multiple) {
                /**
                 * for multiple selected rows go through each selection and move it
                 * as we mutate through the array, while moving, we need to do it on two steps
                 * first keep the reference of parents from where elements need to be moved.
                 * Second, find index not using parsing path value, but searching for items with the same path.
                 */
                const toMoveRows = [];
                selectedRows === null || selectedRows === void 0 ? void 0 : selectedRows.forEach(({ path, index }) => {
                    const { parentPath: parentPathFrom, index: rowIndex } = getIndexAndParentPath(path);
                    const parentFrom = parentPathFrom ? get(draft, parentPathFrom) : draft;
                    const inSameParent = parentPathFrom === parentPathTo;
                    toMoveRows.push({
                        parent: parentFrom,
                        path,
                        row: parentFrom[rowIndex],
                        inSameParent
                    });
                });
                toMoveRows.forEach(({ parent: parentFrom, path, row, inSameParent }) => {
                    const rowIndex = parentFrom.findIndex((item) => item[PATH_KEY] === path);
                    parentFrom.splice(rowIndex, 1);
                    toIndex = toIndex > rowIndex && inSameParent ? toIndex - 1 : toIndex;
                    parentTo.splice(toIndex, 0, row);
                    // add next item to next index
                    toIndex = toIndex + 1;
                });
            }
            else {
                const { parentPath: parentPathFrom } = getIndexAndParentPath(row[PATH_KEY]);
                const parentFrom = parentPathFrom ? get(draft, parentPathFrom) : draft;
                const inSameParent = parentPathFrom === parentPathTo;
                parentFrom.splice(fromIndex, 1);
                toIndex = toIndex > fromIndex && inSameParent ? toIndex - 1 : toIndex;
                parentTo.splice(toIndex, 0, row);
            }
            // fix all the if/elseif/else rows
            correctNestedLogicalRows(draft, null);
            // update paths
            updatePath(draft, '');
        });
        onDataChange(updatedData);
        // after move unselect all rows
        setSelectedRows([]);
        // cleanup the expanded, and expandedRowDetails
        setExpendedRows([]);
        setExpandedRowDetails([]);
    };
    const isReorderDropAllowed = (row, currentRow, dropPosition) => {
        const { parentPath: parentPathFrom } = getIndexAndParentPath(row[PATH_KEY]);
        const { parentPath: parentPathTo, index: currentRowIndex } = getIndexAndParentPath(currentRow[PATH_KEY]);
        const parentTo = (parentPathTo ? get(dataSource, parentPathTo) : dataSource);
        /**
         * If user is trying to drop any row (other then their siblings) between if/elseIf/else rows, don't allow it
         */
        const inSameParent = parentPathFrom === parentPathTo;
        if ([LogicalRows.if, LogicalRows.elseIf, LogicalRows.else].includes(currentRow.rowType)) {
            const hasElse = parentTo.some((item) => item.rowType === LogicalRows.else);
            const endIndex = parentTo.length - (hasElse ? 1 : 0);
            const dropIndex = dropPosition === RowPosition.before ? currentRowIndex : currentRowIndex + 1;
            if (inSameParent && dropIndex <= endIndex)
                return true;
            return false;
        }
        return true;
    };
    const getReorderRows = (row, selectionState) => {
        // if the moving row is not selected no need to calculate selected rows
        const rowState = selectionState[row[rowKey]];
        if (!rowState || !(rowState.checked && !rowState.indeterminate)) {
            return { row };
        }
        const selectedRows = [];
        // addedRows map
        const addedRowsMap = {};
        /**
         * Note that the selectionState is always sorted based on hierarchy and index,
         * so we can just iterate over the selectionState and add the rows to the selectedRows array
         */
        Object.entries(selectionState).forEach(([key, state]) => {
            var _a, _b;
            // if the row's parent is already added it will have the entry in the addedRowsMap, then don't add it
            if (addedRowsMap[key])
                return;
            if (state.checked && !state.indeterminate && !state.disabled) {
                const { path } = state;
                const { index } = getIndexAndParentPath(path);
                selectedRows.push({ path, index });
                addedRowsMap[key] = true;
                (_a = state.nestedChildren) === null || _a === void 0 ? void 0 : _a.forEach((childKey) => {
                    addedRowsMap[childKey] = true;
                });
                // if row is part of the nested children, change the row to this parent row.
                if ((_b = state.nestedChildren) === null || _b === void 0 ? void 0 : _b.includes(row[rowKey])) {
                    row = get(dataSource, path);
                }
            }
        });
        return { row, selectedRows, dragMessage: 'Moving selected rows.' };
    };
    const onDelete = (rowPaths) => {
        const updatedData = produceWithoutFreeze(dataSource, (draft) => {
            /**
             * as rows are being removed from the array, the index and parent on path can't be relied upon,
             * So we delete it two steps, first keep the reference of parents from where elements need to be removed.
             * Second, find index not using parsing path value, but searching for items with the same path.
             * This makes sure that the correct elements are removed
             */
            const toRemoveArray = rowPaths.map((path) => {
                const { parentPath } = getIndexAndParentPath(path);
                /**
                 * as rows are being removed from the array, the index on path can't be relied upon, so
                 * we need to find the element index and remove it
                 */
                const parentArray = (parentPath ? get(draft, parentPath) : draft);
                return { parent: parentArray, path };
            });
            toRemoveArray.forEach(({ parent, path }) => {
                const index = parent.findIndex((item) => item[PATH_KEY] === path);
                parent.splice(index, 1);
            });
            // fix all the if/elseif/else rows
            correctNestedLogicalRows(draft, null);
            // update path for all the elements
            updatePath(draft, '');
        });
        onDataChange(updatedData);
        // after delete unselect all rows
        setSelectedRows([]);
        // cleanup the expanded, and expandedRowDetails
        setExpendedRows([]);
        setExpandedRowDetails([]);
    };
    const onRowSelectionChange = (selectedRows, selectionState, row) => {
        setSelectedRows(selectedRows);
        setLastInteractedRow(row === null || row === void 0 ? void 0 : row[rowKey]);
    };
    const renderMergeAction = usePersistentCallback((selectionState) => {
        // if no row was interacted with, don't show the merge action
        if (!lastInteractedRow)
            return null;
        // only show merge action if last interacted row is logical if catefory
        const row = get(dataSource, selectionState[lastInteractedRow].path);
        if (![LogicalRows.if, LogicalRows.elseIf, LogicalRows.else].includes(row.rowType))
            return null;
        // find sibling if else if rows
        const logicalRowParentPath = getIndexAndParentPath(row[PATH_KEY]).parentPath.replace(/\.children$/, '');
        const { parentPath } = getIndexAndParentPath(logicalRowParentPath);
        const parentArray = (parentPath ? get(dataSource, parentPath) : dataSource);
        // find all logical if groups rows in the same parent which are checked
        const logicalIfGroups = parentArray.filter((row) => {
            return row.rowType === LogicalRows.ifGroup && selectionState[row[rowKey]].checked;
        });
        // if there is single selected logical if group, don't show the merge action
        if (logicalIfGroups.length === 1)
            return null;
        // if multiple logical if groups have else condition, they can't be merged
        const ifGroupsWithElse = logicalIfGroups.filter((row) => row.elseCondition);
        if (ifGroupsWithElse.length > 1)
            return null;
        const mergeRows = () => {
            var _a, _b;
            // merge all if groups to first if group
            const firstIfGroup = logicalIfGroups[0];
            const lastRowOfFirstIfGroup = (_a = firstIfGroup.children) === null || _a === void 0 ? void 0 : _a[firstIfGroup.children.length - 1];
            const rowsToMove = [];
            for (let i = 1; i < logicalIfGroups.length; i++) {
                const ifGroup = logicalIfGroups[i];
                (_b = ifGroup.children) === null || _b === void 0 ? void 0 : _b.forEach((row, index) => {
                    rowsToMove.push({ path: row[PATH_KEY], index });
                });
            }
            onReorder({
                currentRow: lastRowOfFirstIfGroup,
                selectedRows: rowsToMove,
                multiple: true,
                toIndex: firstIfGroup.children.length,
                fromIndex: 0, // doesn't matter if multiple is set to true
                row
            });
        };
        return (_jsx(Button, { variant: 'secondaryGrey', size: 's', onClick: mergeRows, children: "Merge" }));
    });
    const deleteSelectedRows = usePersistentCallback((selectionState) => {
        const selectedRows = Object.values(selectionState)
            .filter((state) => state.checked && !state.disabled && !state.indeterminate)
            .map((state) => state.path);
        onDelete(selectedRows);
    });
    const renderSelectActions = useCallback((selectionState) => {
        return (_jsx(Stack, { direction: 'horizontal', align: 'space-between', alignY: 'center', width: '100%', children: _jsx(StackItem, { children: _jsxs(Stack, { direction: 'horizontal', alignY: 'center', gap: tokens.SelectionActions.gap, children: [renderMergeAction(selectionState), _jsx(Button, { variant: 'secondaryGrey', danger: true, size: 's', onClick: () => deleteSelectedRows(selectionState), children: "Delete" })] }) }) }));
    }, [deleteSelectedRows, renderMergeAction]);
    let _columns = columns === null || columns === void 0 ? void 0 : columns.map((column) => {
        return Object.assign(Object.assign({}, column), { width: typeof column.width === 'string' && column.width.endsWith('fr') ? undefined : column.width, onCell: (record, index) => {
                var _a, _b;
                return Object.assign(Object.assign({}, (_a = column.onCell) === null || _a === void 0 ? void 0 : _a.call(column, record, index)), { customizedCellComponent: (_b = components === null || components === void 0 ? void 0 : components.body) === null || _b === void 0 ? void 0 : _b.cell });
            } });
    });
    if (renderRowDetails) {
        _columns.push({
            width: '40px',
            key: 'expand',
            align: 'center',
            resizable: false,
            render: (row) => {
                const { parentPath, index } = getIndexAndParentPath(row[PATH_KEY]);
                // don't show expand button for the additional row we have in the end
                if (parentPath === '' && index === dataSource.length)
                    return null;
                return (_jsx(TableActions, { width: '100%', height: '100%', alignY: 'center', align: 'center', children: _jsx(TableRowExpandButton, { expanded: expandedRowDetails.includes(row[PATH_KEY]), onClick: () => {
                            if (expandedRowDetails.includes(row[PATH_KEY])) {
                                setExpandedRowDetails(expandedRowDetails.filter((path) => path !== row[PATH_KEY]));
                            }
                            else {
                                setExpandedRowDetails([...expandedRowDetails, row[PATH_KEY]]);
                            }
                        } }) }));
            }
        });
    }
    _columns.push({
        width: '50px',
        key: 'operations',
        align: 'center',
        resizable: false,
        render: (row) => {
            const { parentPath, index } = getIndexAndParentPath(row[PATH_KEY]);
            // don't show delete button for the additional row we have in the end
            if (parentPath === '' && index === dataSource.length)
                return null;
            return (_jsx(TableActions, { width: '100%', height: '100%', alignY: 'center', align: 'center', children: _jsx(TableIconButton, { icon: _jsx(TrashCIcon, { type: 'default' }), onClick: () => onDelete([row[PATH_KEY]]) }) }));
        }
    });
    const renderRowActionsDropdown = (row, position) => {
        // get the correct row from data source, as currently path is used as key, there is chance renderRowActionsDropdown giving previous row
        // FIXME: renderRowActionsDropdown shouldn't be called with wrong only
        row = get(dataSource, row[PATH_KEY]) || buildNewRow(row.__path);
        // if its expanded row, don't show the row actions
        if (row[PATH_KEY].endsWith(EXPANDED_SUFFIX))
            return null;
        const { parentPath, index } = getIndexAndParentPath(row[PATH_KEY]);
        // don't show rowAction button for the additional row we have in the end
        if (parentPath === '' && index === dataSource.length && position === RowPosition.after)
            return null;
        const addRow = (referenceRow, rowType) => {
            const newRowFuncMap = {
                [LogicalRows.if]: () => wrapInIfGroup([createIfRow('')], ''),
                [LogicalRows.elseIf]: () => createElseIfRow(''),
                [LogicalRows.else]: () => createElseRow(''),
                [LogicalRows.for]: () => createForInRow(''),
                row: () => buildNewRow('')
            };
            const newRow = newRowFuncMap[rowType]();
            const updatedData = produceWithoutFreeze(dataSource, (draft) => {
                const { parentPath, index } = getIndexAndParentPath(referenceRow[PATH_KEY]);
                const parent = parentPath ? get(draft, parentPath) : draft;
                let addToIndex = position === RowPosition.before ? index : index + 1;
                // for else row, always add it at the end
                if (rowType === LogicalRows.else) {
                    addToIndex = parent.length;
                }
                parent.splice(addToIndex, 0, newRow);
                // update path for all the elements
                updatePath(draft, '');
            });
            onDataChange(updatedData);
        };
        const rowConfig = canAddIndependentRows(dataSource, row, position);
        const ifConfig = canAddIndependentRows(dataSource, row, position);
        const forConfig = canAddIndependentRows(dataSource, row, position);
        const elseIfConfig = canAddElseIfRow(dataSource, row, position);
        const elseConfig = canAddElseRow(dataSource, row, position);
        return (_jsxs(_Fragment, { children: [_jsxs(Dropdown.Label, { children: ["Insert ", position === RowPosition.before ? 'Above' : 'Below'] }), rowConfig.allowed && (_jsx(Dropdown.Item, { onClick: () => addRow(rowConfig.referenceRow, 'row'), children: newRowLabel })), ifConfig.allowed && (_jsx(Dropdown.Item, { onClick: () => addRow(ifConfig.referenceRow, LogicalRows.if), children: "If" })), elseIfConfig.allowed && (_jsx(Dropdown.Item, { onClick: () => addRow(elseIfConfig.referenceRow, LogicalRows.elseIf), children: "Else If" })), elseConfig.allowed && (_jsx(Dropdown.Item, { onClick: () => addRow(elseConfig.referenceRow, LogicalRows.else), children: "Else" })), forConfig.allowed && (_jsx(Dropdown.Item, { onClick: () => addRow(forConfig.referenceRow, LogicalRows.for), children: "For" }))] }));
    };
    const logicalGroupKeys = getLogicalGroupKeys(dataSource, rowKey, []);
    const memoizedRenderExpandedCell = usePersistentCallback((row) => {
        return renderRowDetails ? renderRowDetails(row) : null;
    });
    const expandedRowsData = useMemo(() => {
        const expandedRowDetailsMap = Object.fromEntries(expandedRowDetails.map((path) => [path, true]));
        return getExpandedDetailsRowsData(dataSource, memoizedRenderExpandedCell, rowKey, expandedRowDetailsMap);
    }, [dataSource, memoizedRenderExpandedCell, rowKey, expandedRowDetails]);
    return (_jsx(ExcelTable, Object.assign({ "data-component": 'code_table' }, restProps, { columns: _columns, dataSource: expandedRowsData, reorder: { onReorder, isReorderDropAllowed, allowMultiLevelReorder: true, getReorderRows }, rowKey: rowKey, newRowLabel: newRowLabel, components: Object.assign(Object.assign({}, components), { body: Object.assign(Object.assign({}, components === null || components === void 0 ? void 0 : components.body), { row: TableRow, cell: TableCell }) }), 
        //@ts-ignore as we rendering custom Table row, we can pass non html attributes to it
        onRow: (row, rowIndex) => {
            var _a;
            return Object.assign(Object.assign({}, onRow === null || onRow === void 0 ? void 0 : onRow(row, rowIndex)), { row,
                dataSource,
                language,
                suggestions,
                prepareSuggestions,
                onRowChange,
                onDataChange,
                buildNewRow,
                expandRow, customizedRowComponent: (_a = components === null || components === void 0 ? void 0 : components.body) === null || _a === void 0 ? void 0 : _a.row, rowComponents });
        }, rowSelection: withRowSelection
            ? {
                selectedRowKeys: selectedRows,
                onChange: onRowSelectionChange,
                renderSelectActions
            }
            : undefined, buildNewRow: buildNewRow, rowAction: {
            type: 'dropdown',
            renderDropdown: renderRowActionsDropdown
        }, expandable: {
            indentSize: 35,
            expandedRowKeys: uniq([...expendedRows, ...logicalGroupKeys]),
            shouldIndentChildren: (row) => {
                return row.rowType !== LogicalRows.ifGroup;
            },
            onExpandedRowsChange: (expandedKeys) => setExpendedRows(expandedKeys)
        } })));
}
