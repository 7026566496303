import { __rest } from "tslib";
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { usePersistentCallback } from '@prophecy/utils/react/hooks';
import { useEffect, useState } from 'react';
import { Stack } from '../Layout';
import { theme } from '../theme';
import { renderWithTooltip } from '../Tooltip';
import { LabelWithHint } from '../Typography/LabelWithHint';
import { Text } from '../Typography/Text';
import { StyledSwitchContainer, StyledSwitchRoot, StyledSwitchThumb } from './styled';
import { tokens } from './tokens';
import { SwitchSize } from './types';
export function Switch(_a) {
    var { checked, defaultChecked, size = SwitchSize.md, disabled, onChange, children, hint, tooltip, helpText, allowIndeterminateByClick } = _a, restProps = __rest(_a, ["checked", "defaultChecked", "size", "disabled", "onChange", "children", "hint", "tooltip", "helpText", "allowIndeterminateByClick"]);
    const [toggle, setToggle] = useState(checked);
    useEffect(() => {
        setToggle(checked);
    }, [checked]);
    const onSwitchChange = usePersistentCallback(() => {
        if (disabled)
            return;
        const prevValue = toggle;
        let nextValue;
        if (prevValue === false) {
            nextValue = allowIndeterminateByClick ? undefined : true;
        }
        else if (prevValue === undefined) {
            nextValue = true;
        }
        else if (prevValue === true) {
            nextValue = false;
        }
        const allowChange = onChange === null || onChange === void 0 ? void 0 : onChange(nextValue);
        if (allowChange) {
            setToggle(nextValue);
        }
    });
    let childrenUI = children ? (_jsx(Text, { level: 'sm', weight: tokens.Label.weight, tone: disabled ? tokens.Label.disabled.color : tokens.Label.color, onClick: onSwitchChange, children: children })) : null;
    if (hint) {
        childrenUI = _jsx(LabelWithHint, { hint: hint, children: childrenUI });
    }
    let switchRootUI = (_jsx(StyledSwitchRoot, Object.assign({ size: size, checked: Boolean(toggle), defaultChecked: defaultChecked, onClick: onSwitchChange, disabled: Boolean(disabled), hasHelpText: Boolean(helpText) }, restProps, { children: _jsx(StyledSwitchThumb, { size: size, disabled: disabled }) })));
    let switchUI = (_jsxs(StyledSwitchContainer, { direction: 'horizontal', gap: theme.spaces.x10, alignY: helpText ? 'start' : 'center', disabled: Boolean(disabled), children: [switchRootUI, helpText ? (_jsxs(Stack, { children: [childrenUI, _jsx(Text, { level: 'xs', tone: tokens.Label.helpTextColor, children: helpText })] })) : (childrenUI)] }));
    if (tooltip) {
        switchUI = _jsx(_Fragment, { children: renderWithTooltip(_jsx("div", { children: switchUI }), tooltip) });
    }
    return switchUI;
}
export * from './types';
export * from './ToggleSwitch';
